import {Component, OnInit, ViewChild } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Person} from '../person';

@Component({
    selector: 'app-start',
    templateUrl: './start.component.html',
    styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {
    public competition = true;
    public newsletter = true;
    public sender: Person = new Person();
    public sending = false;
    @ViewChild('fileInput') fileInput;
    selectedLayout = 1;
    private sent: boolean;
    private showSendingSpinner: boolean;

    constructor(private http: HttpClient

    ) {
    }


    get validated() {
        if (this.sending) {
            return false;
        }
        if (!this.sender.validated) {
            return false;
        }
        if (this.competition || this.newsletter) {
            if (!this.sender.email) {
                return false;
            }
            if (!this.sender.validateEmail) {
                return false;
            }
        }else {
            return false;
        }
        if (this.sender.email) {
            if (!this.sender.validateEmail) {
                return false;
            }
        }
        return true;
    }

    sendPostcard() {
        this.showSendingSpinner = true;
        this.sending = true;
        const httpOptions = {
                headers:
                    new HttpHeaders({
                        'Content-Type': 'application/json'
                    })
            },
            body = {
                senderAddress: this.sender,
                newsletter: this.newsletter,
                competition: this.competition,
                source: window.location.hostname,
                date: Date.now(),
                design: this.selectedLayout
            };

        this.http.post('https://api.rheintal.com/api/send/', body, httpOptions).subscribe(response => {
            this.sending = false;
            this.sent = true;

        }, error => {
            this.sending = false;
        });
    }

    ngOnInit(): void {
    }

}
