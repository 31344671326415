import {validate} from "codelyzer/walkerFactory/walkerFn";

export class Person {
    public lastname: '';
    public firstname: '';
    public street: '';
    public zip: '';
    public city: '';
    public email: '';

    get validated () {
        if (!this.lastname) return false;
        if (!this.firstname) return false;
        if (!this.street) return false;
        if (!this.zip) return false;
        if (!this.city) return false;
        // if (!this.email) return false;
        // if (!this.validateEmail(this.email)) return false;
        return true;
    }

    get validateEmail() {
        if (!this.email) return true;
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(this.email);
    }
}
